@import "../styles/mixins";
@import "../styles/variables";

$size: 38px;

.component {
  @include flex(center);
  margin-bottom: 10px;
  margin-top: 20px;
  background-color: rgba($color: #edf4fe, $alpha: 0.5);
  border-radius: 19px;

  transition: background-color 0.2s;

  height: $size;

  a {
    color: $brand;
    text-decoration: none;
  }

  span {
    color: $line;
    cursor: default;
  }

  &:hover {
    background-color: #edf4fe;
  }
}

.text {
  background: $brand;
  color: white;
  padding: 0 15px;
}

.button {
  opacity: 1;

  height: 100%;
  width: 100%;

  font-size: 12px;
  font-weight: 500;
}
