@import "../styles/variables.scss";

.right {
  position: absolute;
  top: 24px;
  right: 40px;
  @media (max-width: $breakpoint) {
    top: 8px;
    right: 15px;
  }
}

.left {
  position: absolute;
  top: 24px;
  right: 150px;
  @media (max-width: $breakpoint) {
    top: 8px;
    right: 100px;
  }
}
