@mixin drag() {
  -webkit-app-region: drag;
  cursor: default;
}

@mixin flex($justify-content: center, $align-items: center) {
  display: flex;
  justify-content: $justify-content;
  align-items: $align-items;
}

@mixin truncate() {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
