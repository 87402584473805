@import "../styles/variables";

.container {
  position: absolute;
  left: 50%;

  &.top {
    top: 0;
    transform: translate(-50%, -100%);
  }

  &.bottom {
    bottom: 0;
    transform: translate(-50%, 100%);
  }

  z-index: 1000;
  min-width: 80px;

  &.top:after,
  &.bottom:before {
    $size: 6px;
    content: "";
    display: block;
    width: (2 * $size);
    height: (2 * $size);
    border: $size solid transparent;
    margin: 0 auto;
  }

  &.top:after {
    border-top-color: $brand;
  }

  &.bottom:before {
    border-bottom-color: $brand;
  }
}

.content {
  background: $brand;
  border-radius: 5px;
  color: white;
  padding: 10px 12px;
  font-size: 12px;
  font-weight: normal;
  position: relative;
  white-space: pre-line;

  @media (max-width: $breakpoint - 1) {
    left: initial !important;
  }

  &.large {
    padding: 20px 25px;
  }
}
