@import "../styles/variables";

.container {
  border-radius: 5px;
  border: 1px solid $line;
  width: 100%;
  height: 100px;
  background: #fff;
  overflow-x: auto;
  min-width: unset;
  font-size: 14px;
  margin: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
