@use 'sass:math';
@import "../../styles/mixins";
@import "../../styles/variables";

$border: #ebeff8;
$releasing: #459cf4;

.component {
  margin-top: 5px;
  @media (min-width: $breakpoint) {
    @include flex(flex-start, stretch);
  }

  > :last-child {
    flex: 1;
  }
}

.component:not(:last-child) {
  > :last-child {
    padding-bottom: 20px;
  }

  @media (min-width: $breakpoint) {
    /* line */
    .dot:before {
      content: "";
      display: block;
      background: $border;
      width: 1px;

      position: absolute;
      top: 20px;
      bottom: -5px;
      left: math.div(20px - 2px, 2);
      z-index: 0;
    }
  }
}

.status {
  display: flex;
}

.dot {
  width: 30px;
  position: relative;
}

.circle {
  @include flex();
  border-radius: 50%;
  width: 20px;
  height: 20px;

  border: 2px solid $border;
  background-color: $border;
  font-size: 12px;
  font-weight: 500;
}

.percent {
  margin-left: 10px;
  font-size: 14px;
  width: 70px;
}

.amount {
  line-height: 1.2;
  margin-bottom: 10px;
}

.header {
  @include flex(space-between);

  .releaseInfo {
    font-size: 12px;
  }

  strong {
    font-weight: 500;
  }
}

.marginBottom {
  margin-bottom: 10px;
}

.icon {
  margin-right: 5px;
  margin-top: 2px;
}

.releasing {
  font-weight: 500;
  color: $releasing;
}

.progress {
  margin-right: 5px;
  margin-top: 4px;
  color: $releasing;
}

.state {
  margin-right: 5px;
}
